import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Block, Button, Icon, Text } from '../../../../components';
import colors from '../../../../utils/colors';

function Review({ pack }) {
    const history = useHistory();

    return (
        <Container row center middle color={colors.white01}>
            <Block mr={10} width={452}>
                <Text size={56} height={64} fontFamily="GellerBold">Thanks!</Text>
                <Text size={24} height={32} mt={8} fontFamily="GellerBold" width={354}>Your subscription was submitted successfully, ready to start getting things done?</Text>
                <Text size={16} height={24} mt={24} width={354} color={colors.grey03}>Start by exploring and updating your dashboard. If you have any questions, please go and check our "Help" menu.</Text>
                <Block mt={56} flex bottom>
                    <Button label="Go to my account" color={colors.grey01} onClick={() => history.push("/dashboard/home")} />
                </Block>
            </Block>
            {!!pack && <Card ml={10}>
                <Text size={48} height={56} fontFamily="GellerBold">{pack.name}</Text>
                <Text size={16} height={24} mt={12} color={colors.grey03}>{pack.description}</Text>
                <Text size={56} height={64} bold mt={12} fontFamily="GellerBold">{pack.price}€</Text>
                <Text size={14} height={24} mt={4} color={colors.grey03}>{pack.priceDescription}</Text>
                <Block mt={24}>
                    {pack.benefits.map((item, index) => {
                        return (
                            <Block row center mt={index === 0 ? 0 : 8}>
                                <Icon name="check_20px" color={colors.yellow01} />
                                <Text size={14} height={28} color={colors.grey03} ml={12}>{item.item}</Text>
                            </Block>
                        );
                    })}
                </Block>
            </Card>}
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Card = styled(Block)`
    width: 452px;
    /* height: 584px; */
    padding: 52px 40px 40px 40px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
`;

export default Review;
