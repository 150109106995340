import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Block, Text } from '../../../../components';
import colors from '../../../../utils/colors';
import Carousel from './components/Carousel';
import PackItem from './components/PackItem';

function ChoosePlan({ packs = [], selectedPack, onSelectPack }) {
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        onSelectPack(null);
    }, [toggle]);

    return (
        <Container middle color={colors.white01}>
            <Content>
                <ToggleWrapper>
                    <Text size={16} height={32} color={colors.grey01}>Subscription</Text>
                    <Toggle onClick={() => setToggle(!toggle)}>
                        <ToggleBall right={toggle} />
                    </Toggle>
                    <Text size={16} height={32} color={colors.grey03}>By quantity</Text>
                </ToggleWrapper>
                {!toggle &&
                    <Carousel>
                        {packs.filter((pack) => pack.type === "SUBSCRIPTION").map((pack) => <PackItem {...pack} selected={pack.id === selectedPack} onClickSelect={() => onSelectPack(pack.id)} />)}
                    </Carousel>
                }
                {!!toggle &&
                    <Carousel>
                        {packs.filter((pack) => pack.type === "QUANTITY").map((pack) => <PackItem {...pack} selected={pack.id === selectedPack} onClickSelect={() => onSelectPack(pack.id)} />)}
                    </Carousel>
                }
            </Content>
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Content = styled.div`
    display: flex;
    flex-flow: column;
`;

const ToggleWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 45px;
`;

const Toggle = styled.div`
    position: relative;
    width: 80px;
    height: 40px;
    background: ${colors.grey01};
    border-radius: 26px;
    margin: 0 24px;
    cursor: pointer;
`;

const ToggleBall = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${colors.green02};
    border-radius: 26px;
    top: 0;
    left: ${({ right }) => right ? "40px" : "0px"};
`;

export default ChoosePlan;
