import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { Block, Form, FormGroup, Icon, Input, Loading, ModalThatSlides, Portlet, PortletOpenClose, SelectInput, Text, TextAreaInput, TextInput } from '../../../../components';
import { getClientToken } from "../../../../utils/client";
import colors from "../../../../utils/colors";
import ListEducation from '../ListEducation';
import ListOtherCourses from '../ListOtherCourses';
import ListProfessionalExperience from '../ListProfessionalExperience';
import ListTeachingExperience from '../ListTeachingExperience';
import ListTechnicalSkillsHighlights from '../ListTechnicalSkillsHighlights';

export default function ModalCV({ candidatureId, isOpen, onRequestClose, onUpdate }) {
    const formRef = useRef(null);
    const [fetching, setFetching] = useState(false);
    const [candidature, setCandidature] = useState(null);

    const [queryCandidature, { loading: loadingCandidature }] = useLazyQuery(
        gql`
            query candidature($id: ID!) {
                candidature(id: $id) {
                    id
                    status
                    personalName
                    personalNationality
                    personalBirthdate
                    personalGender
                    personalQualifications
                    contactsEmail
                    contactsPhone
                    contactsLocation
                    interviewDetails
                    interviewPersonalDevelopment
                    interviewSalaryExpectations
                    professionalExperience
                    teachingExperience
                    education
                    otherCourses
                    technicalSkills
                    jobOfferId
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidature) {
                    setCandidature(data.candidature);
                }
            }
        }
    );

    const [mutateCandidatureUpdate, { loading: loadingCandidatureUpdate }] = useMutation(
        gql`
            mutation candidatureUpdate(
                $id: ID!,
                $status: CandidatureStatus,
                $personalName: String,
                $personalNationality: String,
                $personalBirthdate: String,
                $personalGender: String,
                $personalQualifications: String,
                $contactsEmail: String,
                $contactsPhone: String,
                $contactsLocation: String,
                $interviewDetails: String,
                $interviewPersonalDevelopment: String,
                $interviewSalaryExpectations: String,
                $professionalExperience: Json,
                $teachingExperience: Json,
                $education: Json,
                $otherCourses: Json,
                $technicalSkills: Json,
                $jobOfferId: String
            ) {
                candidatureUpdate(
                    id: $id
                    status: $status
                    personalName: $personalName
                    personalNationality: $personalNationality
                    personalBirthdate: $personalBirthdate
                    personalGender: $personalGender
                    personalQualifications: $personalQualifications
                    contactsEmail: $contactsEmail
                    contactsPhone: $contactsPhone
                    contactsLocation: $contactsLocation
                    interviewDetails: $interviewDetails
                    interviewPersonalDevelopment: $interviewPersonalDevelopment
                    interviewSalaryExpectations: $interviewSalaryExpectations
                    professionalExperience: $professionalExperience
                    teachingExperience: $teachingExperience
                    education: $education
                    otherCourses: $otherCourses
                    technicalSkills: $technicalSkills,
                    jobOfferId: $jobOfferId
                ) {
                    id
                    status
                    personalName
                    # personalNationality
                    # personalBirthdate
                    # personalGender
                    # personalQualifications
                    # contactsEmail
                    # contactsPhone
                    # contactsLocation
                    # interviewDetails
                    # interviewPersonalDevelopment
                    # interviewSalaryExpectations
                    # professionalExperience
                    # teachingExperience
                    # education
                    # otherCourses
                    # technicalSkills
                    # jobOfferId
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureUpdate) {
                    !!onUpdate && onUpdate(data.candidatureUpdate);
                    !!onRequestClose && onRequestClose();
                }
            }
        }
    );

    useEffect(() => {
        if (!isOpen) {
            setCandidature(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!candidatureId) {
            queryCandidature({ variables: { id: candidatureId } });
        }
    }, [candidatureId]);

    const exportRequest = () => {
        if (!fetching) {
            setFetching(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdfs/generate/cv/${candidatureId}`, {
                method: "GET",
                headers: { "Authorization": getClientToken() }
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetching(false);
                })
                .catch(() => {
                    setFetching(false);
                });
        }
    }

    const STATUS = {
        SENT: "Sent",
        COMPLETED: "Completed",
        CANDIDATURE: "Candidature",
        IN_ANALYSIS: "In Analysis",
        INTERVIEW: "Interview",
        EVIDENCE_MATCH: "Evidence Match",
        REJECTED: "Rejected",
        CONTRACTED: "Contracted"
    }

    return (
        <ModalThatSlides
            id="candidate-cv"
            title="Candidate CV"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Block row>
                    <Block row center pl={16} width={176} height={52} color={colors.yellow01} pointer onClick={exportRequest}
                    >
                        {fetching ?
                            <Loading />
                            :
                            <>
                                <Icon name="upload_20px" color={colors.white01} />
                                <Text size={14} height={28} color={colors.white01} ml={8}>Export CV</Text>
                            </>}
                    </Block>
                    <Block
                        row
                        center
                        pl={16}
                        width={176}
                        height={52}
                        color={colors.grey02}
                        pointer={!loadingCandidatureUpdate}
                        onClick={() => {
                            formRef.current.handleSubmit();
                        }}
                    >
                        {loadingCandidatureUpdate ? <Loading /> : <Text size={14} height={28} color={colors.white01}>Save</Text>}
                    </Block>
                    <Block row center middle width={52} height={52} color={colors.grey01} pointer onClick={onRequestClose}>
                        <Icon name="close_16px" color={colors.white01} />
                    </Block>
                </Block>
            }
        >
            {(loadingCandidature) && <Block width="100%" height="100%" center middle><Loading color={colors.grey01} /></Block>}
            {!!candidature && <Form
                innerRef={formRef}
                initialValues={candidature}
                schema={yup.object().shape({
                    personalName: yup.string().required(),
                    contactsEmail: yup.string().email().required()
                })}
                onSubmit={(values) => {
                    if (!loadingCandidatureUpdate) {
                        mutateCandidatureUpdate({ variables: values });
                    }
                }}
            >
                {({
                    values,
                    errors,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <PortletOpenClose title="General data">
                                <Portlet width="100%">
                                    <FormGroup title="Personal data">
                                        <Block width={456}>
                                            <Input
                                                Component={SelectInput}
                                                error={errors.status}
                                                name="status"
                                                label="Status"
                                                options={Object.keys(STATUS).map((statusKey) => ({ label: STATUS[statusKey], value: statusKey }))} />
                                        </Block>
                                        <Block width={456}>
                                            <Input Component={TextInput} error={errors.jobOfferId} name="jobOfferId" label="Job Offer ID" />
                                        </Block>
                                        <Block width={456}>
                                            <Input Component={TextInput} error={errors.personalName} name="personalName" label="Name" />
                                        </Block>
                                        <Block width={356}>
                                            <Input Component={TextInput} error={errors.personalNationality} name="personalNationality" label="Nationality" />
                                        </Block>
                                        <Block width={186}>
                                            <Input Component={TextInput} error={errors.personalBirthdate} name="personalBirthdate" label="Birthdate" type="date" />
                                        </Block>
                                        <Block width={186}>
                                            <Input Component={TextInput} error={errors.personalGender} name="personalGender" label="Gender" />
                                        </Block>
                                        <Block width={256}>
                                            <Input Component={TextInput} error={errors.personalQualifications} name="personalQualifications" label="Academic Qualifications" />
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                                <Portlet width="100%">
                                    <FormGroup title="Contacts">
                                        <Block width={356}>
                                            <Input Component={TextInput} error={errors.contactsEmail} name="contactsEmail" label="Email" />
                                        </Block>
                                        <Block width={256}>
                                            <Input Component={TextInput} error={errors.contactsPhone} name="contactsPhone" label="Contact" />
                                        </Block>
                                        <Block width={256}>
                                            <Input Component={TextInput} error={errors.contactsLocal} name="contactsLocation" label="Local" />
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                                <Portlet width="100%">
                                    <FormGroup title="Interview briefing">
                                        <Block width={456}>
                                            <Input Component={TextAreaInput} error={errors.interviewPersonalDevelopment} name="interviewPersonalDevelopment" label="Candidate summary" />
                                        </Block>
                                        <Block width={456}>
                                            <Input Component={TextAreaInput} error={errors.interviewDetails} name="interviewDetails" label="Interview details" />
                                        </Block>
                                        <Block width={456}>
                                            <Input Component={TextInput} error={errors.interviewSalaryExpectations} name="interviewSalaryExpectations" label="Salary expectations" />
                                        </Block>
                                    </FormGroup>
                                </Portlet>
                            </PortletOpenClose>
                            <PortletOpenClose title="Professional Experience">
                                <Input Component={ListProfessionalExperience} error={errors.professionalExperience} name="professionalExperience" />
                            </PortletOpenClose>
                            <PortletOpenClose title="Teaching experience / freelancer">
                                <Input Component={ListTeachingExperience} error={errors.teachingExperience} name="teachingExperience" />
                            </PortletOpenClose>
                            <PortletOpenClose title="Education">
                                <Input Component={ListEducation} error={errors.education} name="education" />
                            </PortletOpenClose>
                            <PortletOpenClose title="Other courses">
                                <Input Component={ListOtherCourses} error={errors.otherCourses} name="otherCourses" />
                            </PortletOpenClose>
                            <PortletOpenClose title="Technical Skills Highlights">
                                <Input Component={ListTechnicalSkillsHighlights} error={errors.technicalSkills} name="technicalSkills" />
                            </PortletOpenClose>
                        </form>
                    )
                }}
            </Form>}
        </ModalThatSlides>
    );
}