import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { Block, Button, Form, FormGroup, Input, Portlet, Text, TextInput } from '../../components';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import ModalEmail from './components/ModalEmail';
import ModalManageAccount from './components/ModalManageAccount';
import ModalManageSubscription from './components/ModalManageSubscription';
import ModalReceipts from './components/ModalReceipts';

function Home() {
    const history = useHistory();
    const { user, setUser } = useContext(store);

    const formEditAccountRef = useRef();
    const formEditBillingRef = useRef();

    const [isManageAccountModalOpen, setIsManageAccountModalOpen] = useState(false);
    const [isManageSubscriptionModalOpen, setIsManageSubscriptionModalOpen] = useState(false);
    const [isReceiptsModalOpen, setIsReceiptsModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    const [mutateUserUpdateAccount, { loading: loadingUserUpdateAccount }] = useMutation(
        gql`
            mutation userUpdate($completeName: String, $company: String, $email: String, $password: String) {
                userUpdate(completeName: $completeName, company: $company, email: $email, password: $password) {
                    id
                    completeName
                    company
                    name
                    address
                    city
                    postalCode
                    nif
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    setUser({ ...user, ...data.userUpdate });
                }
            }
        }
    );

    const [mutateUserUpdateBilling, { loading: loadingUserUpdateBilling }] = useMutation(
        gql`
            mutation userUpdate($name: String, $address: String, $city: String, $postalCode: String, $nif: String) {
                userUpdate(name: $name, address: $address, city: $city, postalCode: $postalCode, nif: $nif) {
                    id
                    completeName
                    company
                    name
                    address
                    city
                    postalCode
                    nif
                    email
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    setUser({ ...user, ...data.userUpdate });
                }
            }
        }
    );

    return (
        <>
            <Container>
                <Block row wrap ml={-10} mr={-10}>
                    <Block ml={10} mr={10} mb={16} width="calc(50% - 20px)">
                        <Portlet
                            width="100%"
                            height="224px"
                            actions={
                                <Block>
                                    <Button color={colors.grey01} onClick={() => history.push("/dashboard/evidence-match")}>See more</Button>
                                </Block>
                            }
                        >
                            <Block>
                                <Text size={16} height={24} color={colors.grey01}>Evidence Match</Text>
                                <Text size={12} height={20} color={colors.grey03} mt={20}>Remaining</Text>
                                <Text size={40} height={48} color={colors.grey01}>{!!user.subscription ? "Unlimited" : user.stats.available}</Text>
                            </Block>
                            <Block row mt={8}>
                                <Block mr={52}>
                                    <Text size={12} height={20} color={colors.grey03}>Sent</Text>
                                    <Text size={32} height={36} color={colors.grey01}>{user.stats.sent}</Text>
                                </Block>
                                <Block mr={52}>
                                    <Text size={12} height={20} color={colors.grey03}>Completed</Text>
                                    <Text size={32} height={36} color={colors.grey01}>{user.stats.completed}</Text>
                                </Block>
                                <Block>
                                    <Text size={12} height={20} color={colors.grey03}>Used</Text>
                                    <Text size={32} height={36} color={colors.grey01}>{user.stats.used}</Text>
                                </Block>
                            </Block>
                        </Portlet>
                    </Block>
                    <Block ml={10} mr={10} mb={16} width="calc(50% - 20px)">
                        <Portlet
                            width="100%"
                            height="224px"
                            actions={
                                <Block row>
                                    <Block mr={12}>
                                        <Button color={colors.grey01} onClick={() => setIsReceiptsModalOpen(true)}>Receipts</Button>
                                    </Block>
                                    <Block>
                                        <Button color={colors.yellow01} onClick={() => setIsManageSubscriptionModalOpen(true)}>Manage Subscription</Button>
                                    </Block>
                                </Block>
                            }
                        >
                            <Block>
                                <Text size={16} height={24} color={colors.grey01}>Subscription</Text>
                            </Block>
                            <Block row mt={20}>
                                <Block mr={30}>
                                    <Text size={12} height={20} color={colors.grey03}>Type of subscription</Text>
                                    <Text size={12} height={20} color={colors.grey01}>{!!user.subscription ? "Unlimited Evidence Match" : "No Subscription"}</Text>
                                </Block>
                                <Block mr={30}>
                                    <Text size={12} height={20} color={colors.grey03}>Purchase date</Text>
                                    <Text size={12} height={20} color={colors.grey01}>{!!user.subscription ? user.subscription.purchaseDate : "--"}</Text>
                                </Block>
                                <Block>
                                    <Text size={12} height={20} color={colors.grey03}>Duration</Text>
                                    <Text size={12} height={20} color={colors.grey01}>{!!user.subscription ? user.subscription.duration + " day(s)" : "--"}</Text>
                                </Block>
                            </Block>
                            <Block mt={28}>
                                <Block row mb={8}>
                                    <Text size={12} height={20} color={colors.grey03}>Limit date:</Text>
                                    <Text size={12} height={20} color={colors.grey01} ml={6}>{!!user.subscription ? user.subscription.renovationDate : "--"}</Text>
                                </Block>
                                <Block width="100%" height={8} color={colors.grey04} br={4}>
                                    <Block width={!!user.subscription ? ((100 - (user.subscription.remaining * 100 / user.subscription.duration)) + "%") : "0%"} height={8} color={colors.yellow01} br={4} />
                                </Block>
                            </Block>
                        </Portlet>
                    </Block>
                    <Block ml={10} mr={10} width="calc(50% - 20px)">
                        <Portlet
                            width="100%"
                            height="420px"
                            actions={
                                <Block row>
                                    <Block mr={12}>
                                        <Button color={colors.grey01} loading={loadingUserUpdateAccount} onClick={() => formEditAccountRef.current.handleSubmit()}>Edit</Button>
                                    </Block>
                                    <Block mr={12}>
                                        <Button color={colors.yellow01} onClick={() => setIsManageAccountModalOpen(true)}>Manage Account</Button>
                                    </Block>
                                    <Block>
                                        <Button color={colors.yellow01} onClick={() => setIsEmailModalOpen(true)}>EM - Email</Button>
                                    </Block>
                                </Block>
                            }
                        >
                            <Form
                                innerRef={formEditAccountRef}
                                initialValues={{
                                    completeName: user.completeName,
                                    company: user.company,
                                    email: user.email
                                }}
                                schema={yup.object().shape({
                                    completeName: yup.string().required(),
                                    company: yup.string().required(),
                                    email: yup.string().email().required(),
                                    password: yup.string()
                                })}
                                onSubmit={(values) => mutateUserUpdateAccount({ variables: values })}
                            >
                                <>
                                    <FormGroup title="Account" description="General data">
                                        <Block width={356}><Input Component={TextInput} name="completeName" label="Name" /></Block>
                                        <Block width={256}><Input Component={TextInput} name="company" label="Company" /></Block>
                                    </FormGroup>
                                    <FormGroup description="Access information">
                                        <Block width={356}><Input Component={TextInput} name="email" label="Email" /></Block>
                                        <Block width={256}><Input Component={TextInput} name="password" label="Password" type="password" /></Block>
                                    </FormGroup>
                                </>
                            </Form>
                        </Portlet>
                    </Block>
                    <Block ml={10} mr={10} width="calc(50% - 20px)">
                        <Portlet
                            width="100%"
                            height="420px"
                            actions={
                                <Block row>
                                    <Block>
                                        <Button color={colors.grey01} loading={loadingUserUpdateBilling} onClick={() => formEditBillingRef.current.handleSubmit()}>Edit</Button>
                                    </Block>
                                </Block>
                            }
                        >
                            <Form
                                innerRef={formEditBillingRef}
                                initialValues={{
                                    name: user.name,
                                    address: user.address,
                                    city: user.city,
                                    postalCode: user.postalCode,
                                    nif: user.nif
                                }}
                                schema={yup.object().shape({
                                    name: yup.string().required(),
                                    address: yup.string().required(),
                                    city: yup.string().required(),
                                    postalCode: yup.string().required(),
                                    nif: yup.string().required()
                                })}
                                onSubmit={(values) => mutateUserUpdateBilling({ variables: values })}
                            >
                                <>
                                    <FormGroup title="Billing information" description="Billing information">
                                        <Block width={356}><Input Component={TextInput} name="name" label="Name" /></Block>
                                        <Input Component={TextInput} name="address" label="Address" />
                                        <Block row>
                                            <Block width={156} mr={10}>
                                                <Input Component={TextInput} name="city" label="City" />
                                            </Block>
                                            <Block width={156} ml={10}>
                                                <Input Component={TextInput} name="postalCode" label="Postal Code" />
                                            </Block>
                                        </Block>
                                        <Block width={256}><Input Component={TextInput} name="nif" label="NIF or VAT" /></Block>
                                    </FormGroup>
                                </>
                            </Form>
                        </Portlet>
                    </Block>
                </Block>
            </Container>
            <ModalManageAccount
                isOpen={isManageAccountModalOpen}
                onRequestClose={() => setIsManageAccountModalOpen(false)}
            />
            <ModalManageSubscription
                isOpen={isManageSubscriptionModalOpen}
                onRequestClose={() => setIsManageSubscriptionModalOpen(false)}
            />
            <ModalReceipts
                isOpen={isReceiptsModalOpen}
                onRequestClose={() => setIsReceiptsModalOpen(false)}
            />
            <ModalEmail
                isOpen={isEmailModalOpen}
                onRequestClose={() => setIsEmailModalOpen(false)}
            />
        </>
    );
}

const Container = styled(Block)`
    padding: 44px 120px;
`;

export default Home;
