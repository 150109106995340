import React from 'react';
import ReactCarousel from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';

export default function Carousel(props) {
    const { children } = props;

    const settings = {
        className: "slider variable-width center",
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true
    };

    return (
        <Container>
            <ReactCarousel {...settings}>
                {children}
            </ReactCarousel>
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    overflow: hidden;

    .slick-track {
        display: flex;
    }

    cursor: move;
`;