import { useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useContext, useState } from "react";
import { useCookies } from 'react-cookie';
import { Block, Button, Icon, Modal, Text, UploadInput } from '../../../../components';
import colors from "../../../../utils/colors";
import { store } from "../../../../utils/store";

export default function ModalManageAccount({ isOpen, onRequestClose }) {
    const { user, setUser } = useContext(store);
    const [cookies, setCookie, removeCookie] = useCookies(["evidence_match_session"]);
    const [isModalDeleteAccountOpen, setIsModalDeleteAccountOpen] = useState(false);

    const [mutateUserUpdate, { loading: loadingUserUpdate }] = useMutation(
        gql`
            mutation userUpdate(
                $logo: Upload
            ) {
                userUpdate(
                    logo: $logo
                ) {
                    id
                    logo
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    setUser({ ...user, ...data.userUpdate });
                }
            }
        }
    );

    const [mutateUserDelete, { loading: loadingUserDelete }] = useMutation(
        gql`
            mutation userDelete {
                userDelete
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userDelete) {
                    removeCookie("evidence_match_session", { path: "/" });
                    window.location.reload();
                }
            }
        }
    );

    return (
        <>
            <Modal
                title="Manage Account"
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            >
                <Block>
                    <Text size={12} height={20} color={colors.grey03} mb={8}>Company Logo <Text size={12} height={20} color={colors.red01}>(48px height recommended)</Text></Text>
                    <UploadInput loading={loadingUserUpdate} value={user.logo} onChangeValue={(file) => !!file && mutateUserUpdate({ variables: { logo: file } })} />
                </Block>
                <Block width={"100%"} height={1} color={colors.grey05} mt={24} mb={24} />
                <Block>
                    <Text size={16} height={24} color={colors.red01}>Delete account</Text>
                    <Text size={14} height={17} color={colors.grey01} mt={4} mb={20}>This action will delete your account and all associated data, this action will be permanent.</Text>
                    <Button label="Delete account" color={colors.red01} width="100%" onClick={() => setIsModalDeleteAccountOpen(true)} />
                </Block>
            </Modal>
            <Modal
                isOpen={isModalDeleteAccountOpen}
                onRequestClose={() => setIsModalDeleteAccountOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>This action will delete your account and all associated data, this action will be permanent. Are you sure that you to proceed?</Text>
                </Block>
                <Block>
                    <Button loading={loadingUserDelete} label="Delete account" color={colors.red01} width="100%" onClick={() => mutateUserDelete()} />
                </Block>
            </Modal>
        </>
    );
}