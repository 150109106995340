import Checkout from "./Checkout";
import EvidenceMatch from "./EvidenceMatch";
import Home from "./Home";
import Login from "./Login";
import Signup from "./Signup";
import SignupSuccess from "./SignupSuccess";

export {
    Home,
    EvidenceMatch,
    Login,
    Signup,
    SignupSuccess,
    Checkout
};
