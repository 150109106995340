import * as React from "react"

const Logout = (props) => (
    <svg
        width={14}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.907 1.375A6 6 0 1 1 4 1.428M7 1.375V7.75"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Logout
