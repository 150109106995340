import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect, Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { Block, Icon, Text } from '../../components';
import { EvidenceMatch, Home } from '../../pages';
import { SvgLogout } from '../../svgs';
import checkAuth from '../../utils/checkAuth';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import MenuItem from './MenuItem';

function Dashboard() {
    const { user } = useContext(store);
    const [cookies, setCookie, removeCookie] = useCookies(["evidence_match_session"]);
    const [helpButtonIsOpen, setHelpButtonIsOpen] = useState(false);

    return (
        <Block row width="100%" height="100%" style={{ overflow: "hidden", minWidth: "1366px" }}>
            <Block color={colors.grey01} width={220} height="100%" space="between">
                <Block>
                    <Block width="100%" pt={42} pb={70} center>
                        <Logo src={process.env.PUBLIC_URL + '/img/logo_white.png'} />
                    </Block>
                    <Block width="100%">
                        <MenuItem to="/dashboard/home" text="Dashboard" icon="dashboard_16px" />
                        <MenuItem to="/dashboard/evidence-match" text="Evidence Match" icon="evidence_match_16px" />
                    </Block>
                </Block>
                <Block>
                    <ButtonHelp isOpen={helpButtonIsOpen} row center middle space="between" ml={18} mr={18} pl={24} pt={12} pr={24} pb={12} br={26} onClick={() => setHelpButtonIsOpen(!helpButtonIsOpen)}>
                        <Text size={14} height={28} color={colors.white01}>Help</Text>
                        <Icon name={helpButtonIsOpen ? "up_16px" : "down_16px"} color={colors.white01} />
                        {helpButtonIsOpen && <ButtonHelpItems>
                            <a href="mailto:support@evidencematch.com" style={{ textDecoration: "none" }}>
                                <ButtonHelpItem
                                    row
                                    center
                                    middle
                                    space="between"
                                // onClick={() => window.open((!!user.subscription && user.subscription.duration == 365) ? "mailto:support@evidencematch.com" : "mailto:support@evidencematch.com")}
                                >
                                    <Text size={14} height={28} color={colors.grey01}>Contact us</Text>
                                    <Icon name={"arrow_right_20px"} color={colors.grey01} />
                                </ButtonHelpItem>
                            </a>
                            <Block height="1px" color={colors.grey05} />
                            <ButtonHelpItem row center middle space="between" onClick={() => window.open("https://google.com")}>
                                <Text size={14} height={28} color={colors.grey01}>EM Guide</Text>
                                <Icon name={"upload_20px"} color={colors.grey01} />
                            </ButtonHelpItem>
                        </ButtonHelpItems>}
                    </ButtonHelp>
                    <Block pt={24} pl={18} pb={24}>
                        <Text size={18} height={32} color={colors.white01}>{user.name}</Text>
                        <Text size={12} height={16} color={colors.grey03}>{user.company}</Text>
                    </Block>
                    <Block pointer row center pt={16} pl={18} pb={16} pr={18} color={colors.grey02} onClick={() => {
                        removeCookie("evidence_match_session", { path: '/', domain: '.evidencematch.com' });
                        window.location.href = '/';
                        return false;
                    }}>
                        <SvgLogout />
                        <Text size={14} height={20} color={colors.white01} ml={14}>Logout</Text>
                    </Block>
                </Block>
            </Block>
            <Block width="calc(100% - 220px)" height="100%" color={colors.grey05} style={{ overflowY: "scroll", position: "relative" }}>
                <Switch>
                    <Route exact path="/dashboard/home" component={Home} />
                    <Route exact path="/dashboard/evidence-match" component={EvidenceMatch} />
                    <Route
                        exact
                        path="/dashboard"
                        render={() => <Redirect to="/dashboard/home" />}
                    />
                </Switch>
            </Block>
        </Block>
    );
}

const Logo = styled.img`
    width: 123px;
    height: 14px;
`;

const ButtonHelp = styled(Block)`
    cursor: pointer;
    background-color: ${({ isOpen }) => isOpen ? colors.yellow03 : colors.yellow01};
    position: relative;
    :hover {
        background-color: ${colors.yellow03};
    }
`;

const ButtonHelpItems = styled(Block)`
    background-color: ${colors.white01};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 64px;
    border-radius: 26px;
    overflow: hidden;
`;

const ButtonHelpItem = styled(Block)`
    cursor: pointer;
    padding: 12px 24px;
`;

export default checkAuth(Dashboard);