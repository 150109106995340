import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import SlidingPane from "react-sliding-pane";
import styled from "styled-components";
import { AdvancedTable, Block, Icon, Text } from "../../../../components";
import { SvgClose } from "../../../../svgs";
import colors from "../../../../utils/colors";

export default function ModalReceipts({ isOpen, onRequestClose }) {
    const [receipts, setReceipts] = useState({ edges: [] });
    const paginate = useRef(false);

    const [queryReceipts, { loading: loadingReceipts }] = useLazyQuery(
        gql`
            query receipts($first: Int, $after: String) {
                receipts(first: $first, after: $after) {
                    edges {
                        cursor
                        node {
                            id
                            receiptId
                            receiptUrl
                            receiptDate
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.receipts) {
                    setReceipts({
                        edges: paginate.current ? [...receipts.edges, ...data.receipts.edges] : data.receipts.edges,
                        pageInfo: data.receipts.pageInfo
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const requestReceipts = (params = { next: false }) => {
        const { first, after, next } = params;

        if (next) {
            paginate.current = true;
        }

        queryReceipts({
            variables: {
                first: first || 20,
                after: after || undefined
            }
        });
    }

    useEffect(() => {
        requestReceipts();
    }, []);

    return (
        <SlidingPane
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            width={"100%"}
            hideHeader
        >
            <Wrapper pt={72} pr={120} pb={72} pl={120}>
                <ButtonClose onClick={onRequestClose}>
                    <SvgClose />
                </ButtonClose>
                <Text size={40} height={48}>Your receipts</Text>
                <Block mt={48}>
                    <AdvancedTable
                        emptyLabel="No receipts found!"
                        columns={[
                            {
                                label: "Document id",
                                value: "receiptId",
                            },
                            {
                                label: "Date",
                                value: "receiptDate"
                            },
                            {
                                label: "",
                                value: "actions"
                            }
                        ]}
                        rows={receipts.edges.map((edge) => {
                            return ({
                                receiptId: "Receipt #" + edge.node.receiptId,
                                receiptDate: <Block row center>
                                    <Text color={colors.grey01} size={14} height={20}>{edge.node.receiptDate}</Text>
                                </Block>,
                                actions: <Block row right mr={-16} pointer>
                                    <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => window.open(edge.node.receiptUrl, '_blank')}>
                                        <Icon name="file_text_16px" />
                                    </Block>
                                </Block>
                            });
                        })}
                    />
                </Block>
            </Wrapper>
        </SlidingPane>
    );
}

const Wrapper = styled(Block)`
    height: 100%;
    background-color: ${colors.grey05};
`;

const ButtonClose = styled.div`
    border-radius: 26px;
    width: 52px;
    height: 52px;
    background-color: ${colors.white01};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
`;