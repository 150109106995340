import React from "react";
import styled from 'styled-components';
import colors from "../../../utils/colors";
import Block from '../../Block';
import Text from '../../Text';

function SelectInput(
    {
        label,
        description,
        error,
        onChangeValue,
        value,
        icon,
        options = [],
        ...props
    }
) {
    return (
        <Block width="100%">
            <Block style={{ position: "relative" }}>
                {!!label && <Label><Text size={10} height={12} color={colors.grey03}>{label}</Text></Label>}
                <Input
                    label={label}
                    type="text"
                    error={error}
                    value={value}
                    onChange={(e) => !!onChangeValue && onChangeValue(e.target.value)}
                    {...props}
                >
                    {options.map((option) => <option value={option.value}>{option.label}</option>)}
                </Input>
            </Block>
            {(() => {
                let color = null;
                let text = null;
                if (!!error) { text = typeof error === "string" ? error : null; color = colors.red01; }
                else if (!!description) { text = description; color = colors.grey50; }

                if (!!text) {
                    return (
                        <Block mt={8}>
                            <Text
                                size={11}
                                height={16}
                                color={color}
                            >
                                {error || description}
                            </Text>
                        </Block>
                    );
                }
            })()}
        </Block>
    );
}

export default React.memo(SelectInput);

const Label = styled(Block)`
    position: absolute;
    top: 8px;
    left: 16px;
`;

const Input = styled.select`
    background-color: ${colors.grey06};
    height: 52px;
    color: ${colors.grey01};
    font-size: 14px;
    line-height: 24px;
    font-family: "Normal";
    border: 0;
    padding: ${({ label }) => !!label ? "20px" : "8px"} 16px 8px 16px;
    border-radius: 10px;

    ::placeholder {
        color: ${colors.grey03};
    }
`;

const StatusWrapper = styled(Block)`
    position: absolute;
    top: 16px;
    right: 16px;
`;