import React from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Block, Button, Text } from '../../components';
import { getClientToken } from '../../utils/client';
import colors from '../../utils/colors';

function SignupSuccess() {
    const history = useHistory();
    const [cookies] = useCookies(["evidence_match_session"]);

    if (getClientToken() || cookies.evidence_match_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Container center middle>
            <Center>
                <Block mt={36} center>
                    <Text size={56} height={64} bold>Thanks!</Text>
                    <Text width={400} center size={24} height={32} mt={8} bold>Your registration was submmited succefully, start using now!</Text>
                    <Text width={353} center size={16} height={24} color={colors.grey03} mt={24}>Nam nec leo pulvinar, scelerisque neque vel, iaculis purus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Text>
                    <Block mt={56}>
                        <Button label="Go to my account" color={colors.yellow01} onClick={() => history.push("/dashboard")} />
                    </Block>
                    <Block mt={12}>
                        <Button label="Continue shopping" color={colors.purple04} onClick={() => history.push("/dashboard")} />
                    </Block>
                </Block>
            </Center>
        </Container >
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`

`;

export default SignupSuccess;
