import React from "react";
import { Block, Button, FieldArray, FormGroup, Input, PortletAccordionItem, TextAreaInput } from "../../../../components";
import colors from "../../../../utils/colors";

export default function ListTeachingExperience({ value = [] }) {
    return (
        <FieldArray
            name={"teachingExperience"}
            render={(arrayHelpers) => {
                return (
                    <>
                        {value.map((_item, index) => {
                            return (
                                <PortletAccordionItem
                                    key={index}
                                    title={"Experience" + " #" + (index + 1)}
                                    onClickDelete={() => arrayHelpers.remove(index)}
                                    onClickMoveDown={() => arrayHelpers.move(index, index + 1)}
                                    onClickMoveUp={() => arrayHelpers.move(index, index - 1)}
                                >
                                    <FormGroup>
                                        <Block width={456}>
                                            <Input Component={TextAreaInput} name={`teachingExperience[${index}].activities`} placeholder="Activities in the company" />
                                        </Block>
                                    </FormGroup>
                                </PortletAccordionItem>
                            );
                        })}
                        <Block>
                            <Button width="100%" icon="plus_16px" color={colors.grey01} onClick={() => arrayHelpers.insert(value.length, {})} />
                        </Block>
                    </>
                );
            }}
        />
    );
}