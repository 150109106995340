import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { AdvancedTable, Block, Button, Form, FormGroup, Icon, Input, Modal, SearchBar, Text, TextInput } from '../../components';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import ModalCV from './components/ModalCV';
import ModalEM from './components/ModalEM';

const STATUS = {
    SENT: "Sent",
    COMPLETED: "Completed",
    CANDIDATURE: "Candidature",
    IN_ANALYSIS: "In Analysis",
    INTERVIEW: "Interview",
    EVIDENCE_MATCH: "Evidence Match",
    REJECTED: "Rejected",
    CONTRACTED: "Contracted"
}

function EvidenceMatch() {
    const { setUser, user } = useContext(store);

    const [candidatures, setCandidatures] = useState({ edges: [], pageInfo: { hasNextPage: false } });
    const [search, setSearch] = useState("");
    const [isModalSendEMOpen, setIsModalSendEMOpen] = useState(false);
    const [modalSendEMStatus, setModalSendEMStatus] = useState(1);
    const [isModalEvidenceMatchOpen, setIsModalEvidenceMatchOpen] = useState(false);
    const [modalCVId, setModalCVId] = useState(null);
    const [isModalCandidatureOpen, setIsModalCandidatureOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [modalDeleteId, setModalDeleteId] = useState(null);
    const [isModalNoMoreEmOpen, setIsModalNoMoreEmOpen] = useState(false);

    const paginate = useRef(false);

    const [queryCandidatures, { loading: loadingCandidatures }] = useLazyQuery(
        gql`
            query candidatures($first: Int, $after: String, $search: String) {
                candidatures(first: $first, after: $after, search: $search) {
                    edges {
                        cursor
                        node {
                            id
                            personalName
                            status
                            jobOfferId
                            evidenceMatchSent
                        }
                    }
                    pageInfo {
                        startCursor
                        endCursor
                        hasNextPage
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatures) {
                    setCandidatures({
                        edges: paginate.current ? [...candidatures.edges, ...data.candidatures.edges] : data.candidatures.edges,
                        pageInfo: data.candidatures.pageInfo,
                        candidaturesStats: data.candidaturesStats
                    });
                    paginate.current = false;
                }
            }
        }
    );

    const [queryUserStats, { loading: loadingUserStats }] = useLazyQuery(
        gql`
            query userLogged {
                userLogged {
                    id
                    stats {
                        sent
                        completed
                        available
                        used
                    }
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userLogged) {
                    setUser({ ...user, ...data.userLogged });
                }
            }
        }
    );

    const requestCandidatures = (params = { next: false }) => {
        const { first, next } = params;
        let after;

        if (next) {
            paginate.current = true;
            after = candidatures.edges[candidatures.edges.length - 1].cursor
        }

        queryCandidatures({
            variables: {
                first: first || 20,
                after: after || undefined,
                search: search !== "" ? search : undefined
            }
        });
    }

    useEffect(() => {
        requestCandidatures();
    }, [search]);

    useEffect(() => {
        if (!isModalSendEMOpen) {
            setModalSendEMStatus(0);
        }
    }, [isModalSendEMOpen]);

    const [mutateCandidatureCreate, { loading: loadingCandidatureCreate }] = useMutation(
        gql`
            mutation candidatureCreate($personalName: String!, $contactsEmail: String!) {
                candidatureCreate(personalName: $personalName, contactsEmail: $contactsEmail) {
                    id 
                }
            }
        `,
        {
            onError: () => {
                setIsModalSendEMOpen(false);
                setIsModalNoMoreEmOpen(true);
            },
            onCompleted: (data) => {
                if (data && data.candidatureCreate) {
                    setModalSendEMStatus(1);
                    requestCandidatures();
                    queryUserStats();
                }
            }
        }
    );

    const [mutateCandidatureDelete, { loading: loadingCandidatureDelete }] = useMutation(
        gql`
            mutation candidatureDelete($id: ID!) {
                candidatureDelete(id: $id) {
                    id 
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureDelete) {
                    const index = candidatures.edges.findIndex((edge) => edge.cursor == data.candidatureDelete.id);
                    if (index !== -1) {
                        candidatures.edges.splice(index, 1);
                        setIsModalDeleteOpen(false);
                        setModalDeleteId(null);
                        setCandidatures(...candidatures);
                    }
                }
            }
        }
    );

    return (
        <>
            <Block height="100%" style={{ overflow: "hidden" }}>
                <SearchBar value={search} onChange={setSearch} />
                <Container flex style={{ overflow: "hidden" }}>
                    <Block row space="between">
                        <StatusBar>
                            <StatusBarItem>
                                <Text color={colors.white01} size={14} height={20}>Sent</Text>
                                <Text color={colors.white01} size={14} height={20}>{user.stats.sent}</Text>
                            </StatusBarItem>
                            <StatusBarItem>
                                <Text color={colors.white01} size={14} height={20}>Completed</Text>
                                <Text color={colors.white01} size={14} height={20}>{user.stats.completed}</Text>
                            </StatusBarItem>
                            <StatusBarItem>
                                <Text color={colors.white01} size={14} height={20}>Used</Text>
                                <Text color={colors.white01} size={14} height={20}>{(user.stats.used)}</Text>
                            </StatusBarItem>
                        </StatusBar>
                        <Button label="Send Evidence Match" color={colors.grey01} onClick={() => setIsModalSendEMOpen(true)} />
                    </Block>
                    <Block height="calc(100% - 56px)" pt={24}>
                        <AdvancedTable
                            hasMore={candidatures.pageInfo.hasNextPage}
                            next={() => requestCandidatures({ next: true })}
                            emptyLabel="No Evidence Match was already sent!"
                            columns={[
                                {
                                    label: "Job offer ID",
                                    value: "jobOfferId",
                                },
                                {
                                    label: "Name",
                                    value: "name",
                                },
                                {
                                    label: "Status",
                                    value: "status"
                                },
                                {
                                    label: "",
                                    value: "actions"
                                }
                            ]}
                            rows={candidatures.edges.map((edge) => {
                                return ({
                                    jobOfferId: edge.node.jobOfferId || "-",
                                    name: edge.node.personalName,
                                    status: <Block row center>
                                        <Block width={12} height={12} color={colors.yellow01} br={6} />
                                        <Text color={colors.grey01} size={14} height={20} ml={8}>{STATUS[edge.node.status]}</Text>
                                    </Block>,
                                    actions: <Block row right mr={-16} pointer>
                                        <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalCandidatureOpen(true); setModalCVId(edge.node.id); }}>
                                            <Icon name="file_text_16px" />
                                            <Text color={colors.grey01} size={14} height={20} ml={8}>Candidate CV</Text>
                                        </Block>
                                        <Block row height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { setIsModalEvidenceMatchOpen(true); setModalCVId(edge.node.id); }}>
                                            <Icon name="evidence_match_16px" />
                                            <Text color={colors.grey01} size={14} height={20} ml={8}>Evidence Match</Text>
                                        </Block>
                                        <Block row width={52} height={52} pl={16} pr={16} center style={{ borderLeft: `1px solid ${colors.grey05}` }} onClick={() => { if (edge.node.evidenceMatchSent) { return; } setIsModalDeleteOpen(true); setModalDeleteId(edge.node.id); }}>
                                            <div style={{ opacity: edge.node.evidenceMatchSent ? 0.2 : 1 }}>
                                                <Icon name="trash_16px" />
                                            </div>
                                        </Block>
                                    </Block>
                                });
                            })}
                        />
                    </Block>
                </Container>
            </Block>

            <Modal
                isOpen={isModalDeleteOpen}
                onRequestClose={() => setIsModalDeleteOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>This action will delete the candidate and all associated data, this action will be permanent. Are you sure that you to proceed?</Text>
                </Block>
                <Block>
                    <Button loading={loadingCandidatureDelete} label="Delete candidate" color={colors.red01} width="100%" onClick={() => mutateCandidatureDelete({ variables: { id: modalDeleteId } })} />
                </Block>
            </Modal>

            <Modal
                title="Send Evidence Match"
                isOpen={isModalSendEMOpen}
                onRequestClose={() => setIsModalSendEMOpen(false)}
            >
                <Form
                    schema={yup.object().shape({
                        personalName: yup.string().required(),
                        contactsEmail: yup.string().email().required(),
                    })}
                    onSubmit={(values) => mutateCandidatureCreate({ variables: values })}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <>
                                {modalSendEMStatus === 0 && (<form onSubmit={handleSubmit} autoComplete="off">
                                    <Block>
                                        <FormGroup>
                                            <Input Component={TextInput} error={errors.personalName} name="personalName" label="Candidate name" />
                                            <Input Component={TextInput} error={errors.contactsEmail} name="contactsEmail" label="Email" />
                                        </FormGroup>
                                    </Block>
                                    <Block mt={24}>
                                        <Button type="submit" loading={loadingCandidatureCreate} label="Send Evidence Match" width="100%" />
                                    </Block>
                                </form>)}
                                {modalSendEMStatus === 1 && (<form onSubmit={handleSubmit} autoComplete="off">
                                    <Block center>
                                        <Icon size={32} name="corfirm_32px" color={null} />
                                        <Text size={16} height={24} color={colors.grey01} mt={12}>Send successfully</Text>
                                        <Text size={14} height={17} color={colors.grey03} center mt={4} mb={40}>The Evidence Match was sent successfully to the candidate, it’s process can be seen in the list</Text>
                                    </Block>
                                    <Block>
                                        <Button label="Continue" color={colors.grey01} width="100%" onClick={() => setIsModalSendEMOpen(false)} />
                                    </Block>
                                </form>)}
                            </>
                        )
                    }}
                </Form>
            </Modal>

            <ModalEM
                candidatureId={modalCVId}
                isOpen={isModalEvidenceMatchOpen}
                onRequestClose={() => {
                    setIsModalEvidenceMatchOpen(false);
                    setModalCVId(null);
                }}
            />

            <ModalCV
                candidatureId={modalCVId}
                isOpen={isModalCandidatureOpen}
                onRequestClose={() => {
                    setIsModalCandidatureOpen(false);
                    setModalCVId(null);
                }}
                onUpdate={(candidature) => {
                    const index = candidatures.edges.findIndex((edge) => edge.node.id === candidature.id);
                    candidatures.edges[index].node = { ...candidatures.edges[index].node, ...candidature };
                    setCandidatures(candidatures);
                }}
            />

            <Modal
                isOpen={isModalNoMoreEmOpen}
                onRequestClose={() => setIsModalNoMoreEmOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>You don’t have more Evidence match to use please purchase a subscription or more evidence match</Text>
                </Block>
                <Block>
                    <Button label="Purchase" color={colors.yellow01} width="100%" onClick={() => { }} />
                </Block>
            </Modal>
        </>
    );
}

const Container = styled(Block)`
    padding: 32px 20px;
`;

const StatusBar = styled(Block)`
    flex-flow: row !important;
    width: fit-content;
    height: 52px;
    border-radius: 10px;
    background-color: ${colors.grey01};
`;

const StatusBarItem = styled(Block)`
    width: 236px;
    height: 52px;
    flex-flow: row !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    border-right: 1px solid ${colors.grey02};

    :last-of-type {
        border-right: none;
    }
`;

export default EvidenceMatch;
