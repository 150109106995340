import * as React from "react"

const Close = (props) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m12.25 4-8.5 8.5M12.249 12.5 3.749 4"
            stroke="#000"
            strokeLinecap="round"
        />
    </svg>
)

export default Close
