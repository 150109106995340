import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { Block, Button, Form, Icon, Input, Text, TextInput } from '../../components';
import { SvgEye } from '../../svgs';
import { getClientToken, setClientToken } from '../../utils/client';
import colors from '../../utils/colors';

function Login() {

    const { search } = useLocation();
    const [cookies, setCookie] = useCookies(["evidence_match_session"]);
    const history = useHistory();
    const [viewPassword, setViewPassword] = useState(false);

    const [mutateUserLogin, { loading }] = useMutation(
        gql`
            mutation userLogin($email: String!, $password: String!) {
                userLogin(email: $email, password: $password) {
                    token
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userLogin) {
                    setClientToken(data.userLogin.token);
                    setCookie("evidence_match_session", data.userLogin.token, { path: '/', domain: '.evidencematch.com' });
                }
            }
        }
    );

    if (getClientToken() || cookies.evidence_match_session) {
        if (search.length > 0) {
            const params = new URLSearchParams(search);
            history.push("/" + params.get("redirect"));
        }

        else {
            history.push("/dashboard");
        }

        return null;
    }

    return (
        <Container center middle>
            <ButtonBack center middle pointer onClick={() => window.location = "https://evidencematch.com/"}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>
            <Center br={16} width={452} height={432} pt={24} pr={24} pb={24} pl={24}>
                <Text size={24} height={32} bold>Login</Text>
                <Form
                    schema={yup.object().shape({
                        email: yup.string().email().required(),
                        password: yup.string().required()
                    })}
                    onSubmit={(values) => {
                        mutateUserLogin({ variables: values });
                    }}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <Block mt={36}>
                                    <Text size={12} height={16} mb={12} bold>EMAIL</Text>
                                    <Input Component={TextInput} name="email" placeholder="Profissional or personal email" />
                                </Block>
                                <Block mt={20}>
                                    <Text size={12} height={16} mb={12} bold>PASSWORD</Text>
                                    <div style={{ position: "relative" }}>
                                        <Input Component={TextInput} type={viewPassword ? "text" : "password"} name="password" placeholder="Password associated" />
                                        <div style={{ cursor: "pointer", position: "absolute", right: 16, top: 18 }} onClick={() => setViewPassword(!viewPassword)}>
                                            <SvgEye />
                                        </div>
                                    </div>
                                </Block>
                                <Block mt={16}>
                                    <Text size={14} height={24}>Forgot password! <Text size={14} height={24} color={colors.yellow01} underline pointer onClick={() => history.push("/password-recovery")}>Recover</Text></Text>
                                </Block>
                                <Block mt={44} row space="between" center>
                                    <Button type="submit" label="Sign in" loading={loading} color={colors.purple04} width="137px" />
                                    <Text size={14} height={24}>I don’t have an account! <Text size={14} height={24} color={colors.yellow01} underline pointer onClick={() => history.push(`/signup${search}`)}>Sign up</Text></Text>
                                </Block>
                            </form>
                        )
                    }}
                </Form>
            </Center>
        </Container >
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default Login;
