import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";
import Icon from "../Icon";

export default function SearchBar({ value, onChange }) {
    return (
        <Wrapper row center pl={24}>
            <Icon name="search_16px" color={colors.grey01} />
            <Input type="text" value={value} onChange={(e) => !!onChange && onChange(e.target.value)} placeholder="Search for candidates" />
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 52px;
    background-color: ${colors.white01};
`;

const Input = styled.input`
    border: none;
    height: 52px;
    width: 100%;
    padding: 0 44px 0 12px;
    font-family: Normal;
    font-size: 12px;
    line-height: 20px;

    ::placeholder {
        color: ${colors.grey02};
    }
`;