import * as React from "react"

const Eye = (props) => (
    <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12c3.866 0 7-1.79 7-4s-3.134-4-7-4-7 1.79-7 4 3.134 4 7 4Zm0-1a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
            fill="#7F7F80"
        />
        <path
            d="M14.5 8c0 .848-.606 1.713-1.798 2.394C11.523 11.068 9.862 11.5 8 11.5v1c2.004 0 3.843-.463 5.198-1.238 1.34-.766 2.302-1.9 2.302-3.262h-1ZM8 4.5c1.862 0 3.523.432 4.702 1.106C13.894 6.287 14.5 7.152 14.5 8h1c0-1.361-.961-2.496-2.302-3.263C11.843 3.963 10.004 3.5 8 3.5v1ZM1.5 8c0-.848.606-1.713 1.798-2.394C4.477 4.932 6.138 4.5 8 4.5v-1c-2.004 0-3.843.463-5.198 1.237C1.462 5.504.5 6.64.5 8h1ZM8 11.5c-1.862 0-3.523-.432-4.702-1.106C2.106 9.713 1.5 8.848 1.5 8h-1c0 1.361.961 2.496 2.302 3.262C4.157 12.037 5.996 12.5 8 12.5v-1ZM10.5 8A2.5 2.5 0 0 1 8 10.5v1A3.5 3.5 0 0 0 11.5 8h-1ZM8 5.5A2.5 2.5 0 0 1 10.5 8h1A3.5 3.5 0 0 0 8 4.5v1ZM5.5 8A2.5 2.5 0 0 1 8 5.5v-1A3.5 3.5 0 0 0 4.5 8h1ZM8 10.5A2.5 2.5 0 0 1 5.5 8h-1A3.5 3.5 0 0 0 8 11.5v-1Z"
            fill="#7F7F80"
        />
        <circle cx={8} cy={8} r={1} fill="#7F7F80" />
    </svg>
)

export default Eye
