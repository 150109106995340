import React from 'react';
import styled from 'styled-components';
import { Block, Button, Icon, Text } from '../../../../../../components';
import colors from '../../../../../../utils/colors';

export default function PackItem(props) {
    const {
        name,
        description,
        price,
        priceDescription,
        benefits = [],
        selected,
        onClickSelect
    } = props;

    return (
        <Item>
            <Block>
                <Text size={48} height={56} fontFamily={"GellerBold"}>{name}</Text>
            </Block>
            <Block mt={12}>
                <Text size={16} height={24} color={colors.grey03}>{description}</Text>
            </Block>
            <Block mt={12}>
                <Text size={56} height={64} fontFamily={"GellerBold"} color={colors.purple04}>{price}€</Text>
            </Block>
            <Block mt={4}>
                <Text size={14} height={24} color={colors.grey03}>{priceDescription}</Text>
            </Block>
            <Block mt={38}>
                {benefits.map((item, index) => {
                    return (
                        <Block row center mt={index === 0 ? 0 : 8}>
                            <Icon name="check_20px" color={colors.yellow01} />
                            <Text size={14} height={28} color={colors.grey03} ml={12}>{item.item}</Text>
                        </Block>
                    );
                })}
            </Block>
            <Block mt={32} flex bottom>
                <Button label={selected ? "Selected" : "Select"} color={selected ? colors.purple04 : colors.grey01} width="100%" onClick={onClickSelect} />
            </Block>
        </Item>
    );
}

const Item = styled.div`
    display: flex;
    flex-flow: column;
    width: 452px;
    padding: 52px 40px 36px 40px;
    background: ${colors.white01};
    border-radius: 16px;
    margin: 0 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 1200px) {
        max-width: 452px;
        width: auto;
        height: auto;
    }
`;

const IconCheck = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 12px;
`;