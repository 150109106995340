import * as React from "react"

const Check = (props) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect width={20} height={20} rx={2} fill="#F4F4F4" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.22 14.214a1 1 0 0 1-.714-.293l-3.214-3.214a1 1 0 1 1 1.414-1.414l2.498 2.498 6.08-6.24a1 1 0 0 1 1.432 1.397L8.93 13.912a1 1 0 0 1-.71.302Z"
            fill="#EDC683"
        />
    </svg>
)

export default Check
