import { useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useContext, useRef } from "react";
import * as yup from 'yup';
import { Block, Button, Form, FormGroup, Input, Modal, TextAreaInput, TextInput } from '../../../../components';
import colors from "../../../../utils/colors";
import { store } from "../../../../utils/store";

export default function ModalEmail({ isOpen, onRequestClose }) {
    const { user, setUser } = useContext(store);
    const formRef = useRef(null);

    const [mutateUserUpdate, { loading: loadingUserUpdate }] = useMutation(
        gql`
            mutation userUpdate(
                $emailEvidenceMatchLink: Json!
            ) {
                userUpdate(
                    emailEvidenceMatchLink: $emailEvidenceMatchLink
                ) {
                    id
                    emailEvidenceMatchLink
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userUpdate) {
                    setUser({ ...user, ...data.userUpdate });
                }
            }
        }
    );

    return (
        <Modal
            title="Evidence email configuration"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
        >
            <Block>
                <Form
                    innerRef={formRef}
                    initialValues={{ ...user.emailEvidenceMatchLink }}
                    schema={yup.object().shape({
                        subject: yup.string().required(),
                        body: yup.string().required(),
                        button: yup.string().required(),
                    })}
                    onSubmit={(values) => {
                        if (!loadingUserUpdate) {
                            mutateUserUpdate({ variables: { emailEvidenceMatchLink: values } });
                        }
                    }}
                >
                    {({
                        errors,
                        handleSubmit,
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <FormGroup title="Email info">
                                    <Block>
                                        <Input Component={TextInput} error={errors.subject} name="subject" label="Subject" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextAreaInput} style={{ height: "296px", resize: "none", overflowY: "scroll" }} error={errors.body} name="body" label="Body" />
                                    </Block>
                                    <Block>
                                        <Input Component={TextInput} error={errors.button} name="button" label="Button" />
                                    </Block>
                                </FormGroup>
                            </form>
                        )
                    }}
                </Form>
            </Block>
            <Block width={"100%"} height={1} color={colors.grey05} mt={24} mb={24} />
            <Block>
                <Button label="Edit" color={colors.grey01} loading={loadingUserUpdate} onClick={() => formRef.current.handleSubmit()} />
            </Block>
        </Modal>
    );
}