import { gql, useLazyQuery } from '@apollo/react-hooks';
import React, { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { getClientToken, setClientToken } from './client';
import { store } from './store';

export default (Component, redirect) => {
    return () => {
        const { setUser, user } = useContext(store);
        const [cookies, setCookie, removeCookie] = useCookies(["evidence_match_session"]);
        const history = useHistory();

        const [queryUserLogged] = useLazyQuery(
            gql`
                query userLogged {
                    userLogged {
                        id
                        completeName
                        company
                        name
                        address
                        city
                        postalCode
                        nif
                        email
                        logo
                        twoLetterName
                        emailEvidenceMatchLink
                        subscription {
                            id
                            interval
                            purchaseDate
                            renovationDate
                            duration
                            remaining
                        }
                        stats {
                            sent
                            completed
                            available
                            used
                        }
                    }
                }
            `,
            {
                onError: (errors) => {
                    setClientToken(null);
                    removeCookie("evidence_match_session", { path: '/', domain: '.evidencematch.com' });
                    history.push("/login");
                },
                onCompleted: (data) => {
                    if (data && data.userLogged) {
                        setUser(data.userLogged);
                    } else {
                        
                    }
                }
            }
        );

        if (cookies.evidence_match_session) {
            setClientToken(cookies.evidence_match_session);
        }

        useEffect(() => {
            if (getClientToken()) {
                queryUserLogged();
            }
        }, []);

        if (getClientToken()) {
            if (!user) {
                return null;
            }
            return <Component />
        } else {
            if (!!redirect) {
                history.push(`/login?redirect=${redirect}`);
            } else {
                history.push(`/login`);
            }
        }

        return null;
    }
}