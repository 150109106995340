import { useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Block, Button, Icon, Modal, Text } from '../../../../components';
import colors from "../../../../utils/colors";
import { store } from "../../../../utils/store";

export default function ModalManageSubscription({ isOpen, onRequestClose }) {
    const { user, setUser } = useContext(store);
    const [isModalCancelSubscriptionOpen, setIsModalCancelSubscriptionOpen] = useState(false);
    const history = useHistory();

    const [mutateSubscriptionCancel, { loading: loadingSubscriptionCancel }] = useMutation(
        gql`
            mutation subscriptionCancel {
                subscriptionCancel
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.subscriptionCancel) {
                    setUser({ ...user, subscription: null });
                    setIsModalCancelSubscriptionOpen(false);
                    onRequestClose();
                }
            }
        }
    );

    return (
        <>
            <Modal
                title="Manage Subscription"
                isOpen={isOpen}
                onRequestClose={onRequestClose}
            >
                {!user.subscription ?
                    <Block>
                        <ButtonBuy onClick={() => history.push("/checkout")}>
                            <Text size={14} height={20} color={colors.grey01}>Buy a Subscription or Evidence Matches</Text>
                            <Icon name={"arrow_right_20px"} size={20} color={colors.grey01} />
                        </ButtonBuy>
                    </Block>
                    :
                    <Block>
                        <Text size={16} height={24} color={colors.red01}>Cancel</Text>
                        <Text size={14} height={17} color={colors.grey01} mt={4} mb={20}>This action will cancel your subcription or Evidence Matches bought permanently</Text>
                        <Button label="Cancel Subscription" color={colors.red01} width="100%" onClick={() => setIsModalCancelSubscriptionOpen(true)} />
                    </Block>
                }
            </Modal>
            <Modal
                isOpen={isModalCancelSubscriptionOpen}
                onRequestClose={() => setIsModalCancelSubscriptionOpen(false)}
            >
                <Block center>
                    <Icon size={32} name="warning_32px" color={colors.red01} />
                    <Text size={16} height={24} color={colors.red01} mt={12}>Warning</Text>
                    <Text size={14} height={17} color={colors.grey01} center mt={4} mb={40}>This action will cancel your subcription or Evidence Matches bought permanently. Are you sure that you want to proceed?</Text>
                </Block>
                <Block>
                    <Button loading={loadingSubscriptionCancel} label="Cancel Subscription" color={colors.red01} width="100%" onClick={() => mutateSubscriptionCancel()} />
                </Block>
            </Modal>
        </>
    );
}
const ButtonBuy = styled.div`
    border-radius: 10px;
    background-color: ${colors.grey06};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 24px;
    cursor: pointer;
`