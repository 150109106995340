import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { Route, Switch } from "react-router-dom";
import styled from 'styled-components';
import { Block } from '../../components';
import { Checkout, Login, Signup, SignupSuccess } from '../../pages';
import PasswordRecovery from '../../pages/PasswordRecovery';
import PasswordRecoveryConfirmation from '../../pages/PasswordRecoveryConfirmation';
import { SvgLogoBlack } from '../../svgs';

function EmptyWithNavigationBack({ }) {
    return (
        <Container width="100%" height="100%" style={{ overflow: "auto", minWidth: "1366px" }}>
            <Logo center onClick={() => window.location = "https://evidencematch.com/"}>
                <SvgLogoBlack />
            </Logo>
            <Block width="100%" height="100%">
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/signup-success" component={SignupSuccess} />
                    <Route exact path="/checkout" component={Checkout} />
                    <Route exact path="/password-recovery/:token" component={PasswordRecoveryConfirmation} />
                    <Route exact path="/password-recovery" component={PasswordRecovery} />

                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to="/login" />}
                    />
                </Switch>
            </Block>
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
`;

const Logo = styled(Block)`
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    z-index: 99;
    cursor: pointer;
`;

export default EmptyWithNavigationBack;