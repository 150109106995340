import SvgCheck from "./Check";
import SvgClose from "./Close";
import SvgEye from "./Eye";
import SvgLogo from "./Logo";
import SvgLogoBlack from "./LogoBlack";
import SvgLogout from "./Logout";

export {
    SvgLogo,
    SvgLogoBlack,
    SvgCheck,
    SvgClose,
    SvgLogout,
    SvgEye
};

