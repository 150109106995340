import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import Block from "../Block";
import Icon from "../Icon";
import Text from "../Text";

export default function PortletAccordionItem(
    {
        title,
        onClickDelete,
        onClickMoveUp,
        onClickMoveDown,
        children
    }
) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper br={16}>
            <Block isOpen={isOpen} space="between" row pointer pt={16} pl={24} pr={24} pb={16}>
                <Text size={14} height={20} color={colors.grey01}>{title}</Text>
                <Block row>
                    <Block mr={8} onClick={onClickDelete}>
                        <Icon name={"trash_16px"} color={colors.grey01} />
                    </Block>
                    <Block mr={8} onClick={onClickMoveDown}>
                        <Icon name={"arrow_down_16px"} color={colors.grey01} />
                    </Block>
                    <Block mr={8} onClick={onClickMoveUp}>
                        <Icon name={"arrow_up_16px"} color={colors.grey01} />
                    </Block>
                    <Block onClick={() => setIsOpen(!isOpen)}>
                        <Icon name={isOpen ? "down_16px" : "up_16px"} color={colors.grey01} />
                    </Block>
                </Block>
            </Block>
            <Content open={isOpen}>
                {isOpen && <Block height="1px" color={colors.grey05} />}
                <Block pt={20} pl={24} pr={24} pb={24}>
                    {children}
                </Block>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled(Block)`
    background-color: ${colors.white01};
    margin-bottom: 12px;

    :last-of-type {
        margin-bottom: 0;
    }
`;

const Content = styled(Block)`
    height: ${({ open }) => !!open ? "fit-content" : "0px"};
    overflow: hidden;
`;