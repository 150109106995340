import React from 'react';
import styled from 'styled-components';
import { Block } from '../../../../components';
import colors from '../../../../utils/colors';

function PaymentMethod({ }) {
    return (
        <Container row center middle color={colors.white01}>
            <div id="easypay-checkout"></div>
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

export default PaymentMethod;
