import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { Block, Button, Form, Icon, Input, Text, TextInput } from '../../components';
import { SvgEye } from '../../svgs';
import { getClientToken, setClientToken } from '../../utils/client';
import colors from '../../utils/colors';

function Signup() {

    const { search } = useLocation();
    const history = useHistory();
    const [cookies, setCookie] = useCookies(["evidence_match_session"]);
    const [viewPassword, setViewPassword] = useState(false);

    const [mutateUserSignup, { loading }] = useMutation(
        gql`
            mutation userSignup($completeName: String!, $company: String!, $name: String!, $address: String!, $city: String!, $postalCode: String!, $nif: String!, $email: String!, $password: String!) {
                userSignup(completeName: $completeName, company: $company, name: $name, address: $address, city: $city, postalCode: $postalCode, nif: $nif, email: $email, password: $password) {
                    token
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userSignup) {
                    setClientToken(data.userSignup.token);
                    setCookie("evidence_match_session", data.userSignup.token, { path: '/' });
                    history.push("/signup-success")
                }
            }
        }
    );

    if (getClientToken() || cookies.evidence_match_session) {
        if (search.length > 0) {
            const params = new URLSearchParams(search);
            history.push("/" + params.get("redirect"));
        }

        else {
            history.push("/dashboard");
        }

        return null;
    }

    return (
        <>
            <ButtonBack center middle pointer onClick={() => history.goBack()}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>

            <Container center style={{ overflow: "auto" }}>
                <Center br={16} width={688}>
                    <Block pt={24} pr={24} pl={24}>
                        <Text size={24} height={32} bold>Signup</Text>
                    </Block>
                    <Form
                        schema={yup.object().shape({
                            completeName: yup.string().required(),
                            company: yup.string().required(),
                            name: yup.string().required(),
                            address: yup.string().required(),
                            city: yup.string().required(),
                            postalCode: yup.string().required(),
                            nif: yup.string().required(),
                            email: yup.string().email().required(),
                            password: yup.string().required()
                        })}
                        onSubmit={(values) => {
                            mutateUserSignup({ variables: values });
                        }}
                    >
                        {({
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block pt={24} pr={24} pb={24} pl={24}>
                                        <Text size={20} height={24}>General data</Text>
                                        <Block mt={28}>
                                            <Text size={12} height={16} mb={12} bold>NAME</Text>
                                            <Input Component={TextInput} error={errors.completeName} name="completeName" placeholder="Complete name" />
                                        </Block>
                                        <Block mt={20}>
                                            <Text size={12} height={16} mb={12} bold>COMPANY</Text>
                                            <Input Component={TextInput} error={errors.company} name="company" placeholder="Company" />
                                        </Block>
                                    </Block>

                                    <Block height={1} color={colors.grey05} />

                                    <Block pt={24} pr={24} pb={24} pl={24}>
                                        <Text size={20} height={24}>Billing information</Text>
                                        <Block mt={28} width={546}>
                                            <Text size={12} height={16} mb={12} bold>NAME</Text>
                                            <Input Component={TextInput} error={errors.name} name="name" placeholder="Personal or company" />
                                        </Block>
                                        <Block mt={20}>
                                            <Text size={12} height={16} mb={12} bold>ADDRESS</Text>
                                            <Input Component={TextInput} error={errors.address} name="address" placeholder="Full address" />
                                        </Block>
                                        <Block mt={20} row>
                                            <Block width={192}>
                                                <Text size={12} height={16} mb={12} bold>CITY</Text>
                                                <Input Component={TextInput} error={errors.city} name="city" placeholder="City" />
                                            </Block>
                                            <Block width={118} ml={20}>
                                                <Text size={12} height={16} mb={12} bold>POSTAL CODE</Text>
                                                <Input Component={TextInput} error={errors.postalCode} name="postalCode" placeholder="0000-000" />
                                            </Block>
                                        </Block>
                                        <Block mt={20} width={310}>
                                            <Text size={12} height={16} mb={12} bold>NIF OR VAT</Text>
                                            <Input Component={TextInput} error={errors.nif} name="nif" placeholder="Valid NIF or VAT" />
                                        </Block>
                                    </Block>

                                    <Block height={1} color={colors.grey05} />

                                    <Block pt={24} pr={24} pb={24} pl={24}>
                                        <Text size={20} height={24}>Access Information</Text>
                                        <Block mt={28}>
                                            <Text size={12} height={16} mb={12} bold>EMAIL</Text>
                                            <Input Component={TextInput} error={errors.email} name="email" placeholder="Profissional or personal email" />
                                        </Block>
                                        <Block mt={20}>
                                            <Text size={12} height={16} mb={12} bold>PASSWORD</Text>
                                            <div style={{ position: "relative" }}>
                                                <Input Component={TextInput} type={viewPassword ? "text" : "password"} name="password" placeholder="Password" />
                                                <div style={{ cursor: "pointer", position: "absolute", right: 16, top: 18 }} onClick={() => setViewPassword(!viewPassword)}>
                                                    <SvgEye />
                                                </div>
                                            </div>
                                        </Block>
                                    </Block>

                                    <Block height={1} color={colors.grey05} />

                                    <Block pt={24} pr={24} pb={0} pl={24}>
                                        <Text size={14} height={16}>By creating an account, you agree with our <a href="https://evidencematch.com/legal" target="_blank">Terms</a> and have read and acknowledged our <a href="https://evidencematch.com/legal" target="_blank">Legal statement</a>.</Text>
                                    </Block>

                                    <Block pt={18} pr={24} pb={18} pl={24}>
                                        <Button type="submit" loading={loading} label="Submit registration" color={colors.purple04} />
                                    </Block>
                                </form>
                            )
                        }}
                    </Form>
                </Center>
            </Container>
        </>
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`
    background-color: ${colors.white01};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
    margin-top: 132px;
    margin-bottom: 96px;
    z-index: 100;
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default Signup;
