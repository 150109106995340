import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Block, Icon, Stepper, Text } from '../../components';
import checkAuth from '../../utils/checkAuth';
import colors from '../../utils/colors';
import { store } from '../../utils/store';
import ChoosePlan from './screens/ChoosePlan';
import PaymentMethod from './screens/PaymentMethod';
import Review from './screens/Review';

function Checkout() {
    const { user } = useContext(store);
    const history = useHistory();
    const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
    const [selectedPack, setSelectedPack] = useState(null);
    const [loadingCheckout, setLoadingCheckout] = useState(false);
    const [packs, setPacks] = useState([]);

    const [queryPacks] = useLazyQuery(
        gql`
            query frontendPacks {
                frontendPacks {
                    id
                    name
                    description
                    priceDescription
                    benefits
                    type
                    price
                    quantity
                    interval
                }
            }
        `,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.frontendPacks) {
                    setPacks(data.frontendPacks);
                }
            }
        }
    );

    useEffect(() => {
        queryPacks();
    }, []);

    const [mutationPackPayment] = useMutation(
        gql`
            mutation packPayment($id: ID!) {
                packPayment(id: $id)
            }
        `,
        {
            onError: () => {

            },
            onCompleted: (data) => {
                if (data && data.packPayment) {
                    window.EasypayCheckoutUI.setup(data.packPayment).then(function (modal) {
                        modal.onCancel(function () {
                            // This is triggered on popup close, if the checkout is not complete
                            alert('The checkout was canceled!');
                        });

                        modal.onClose(function () {
                            setCurrentScreenIndex(2);
                        });

                        modal.onComplete(function (data) {
                            // setCurrentScreenIndex(2);
                        });
                    });
                    setTimeout(() => {
                        setLoadingCheckout(false);
                        document.getElementById("easypay-checkout-open").click();
                        setCurrentScreenIndex(currentScreenIndex + 1);
                    }, 1000);
                }
            }
        }
    );

    if (!!user.subscription) {
        history.push("/dashboard/home");
    }

    return (
        <Container center middle>
            <ButtonBack center middle pointer onClick={() => history.goBack()}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>
            <ButtonAccount center middle pointer onClick={() => history.push("/dashboard/home")}>
                <Text size={14} height={16}>{user.twoLetterName}</Text>
            </ButtonAccount>
            <Stepper
                showNext={!!selectedPack}
                loadingNext={loadingCheckout}
                currentIndex={currentScreenIndex}
                onClickPrevious={() => setCurrentScreenIndex(currentScreenIndex - 1)}
                onClickNext={() => {
                    if (currentScreenIndex === 0) {
                        setLoadingCheckout(true);
                        mutationPackPayment({ variables: { id: selectedPack } });
                    } else {
                        setCurrentScreenIndex(currentScreenIndex + 1)
                    }
                }}
                screens={[
                    {
                        title: "Choose Plan",
                        screen: <ChoosePlan packs={packs} selectedPack={selectedPack} onSelectPack={setSelectedPack} />
                    },
                    {
                        title: "Payment method",
                        screen: <PaymentMethod />
                    },
                    {
                        title: "Review",
                        screen: <Review pack={!!selectedPack && packs.find((pack) => pack.id === selectedPack)} />
                    }
                ]}
            />
        </Container>
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 1000px;
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

const ButtonAccount = styled(Block)`
    position: absolute;
    top: 24px;
    right: 22px;
    width: 52px;
    height: 52px;
    background: ${colors.yellow01};
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default checkAuth(Checkout, "checkout");
