import React from "react";
import styled from 'styled-components';
import colors from "../../utils/colors";
import Block from '../Block';
import Button from "../Button";
import Text from "../Text";

function AdvancedTable({ columns = [], rows = [], emptyLabel, next, hasMore }) {
    return (
        <Wrapper style={{ overflow: "auto" }}>
            <Columns row center height={40}>
                {columns.map((column, columnIndex) => {
                    return (
                        <Column key={columnIndex} row center flex={!column.width} width={column.width} space="between">
                            <Text size={12} height={20} color={colors.grey01}>{column.label}</Text>
                        </Column>
                    );
                })}
            </Columns>
            <Rows flex>
                {(rows.length === 0 && !!emptyLabel) &&
                    <Block middle width="100%" height={52} br={10} color={colors.yellow01} pl={24} pr={24}>
                        <Text size={14} height={20}>{emptyLabel}</Text>
                    </Block>
                }
                {rows.map((row, rowIndex) => {
                    return (
                        <Row key={rowIndex}>
                            <Block row flex>
                                {columns.map((column, columnIndex) => {
                                    return (
                                        <RowCell key={columnIndex} flex={!column.width} width={column.width}>
                                            {typeof row[column.value] === "string" ?
                                                <Text size={14} height={24} color={colors.grey100}>{row[column.value]}</Text>
                                                :
                                                row[column.value]
                                            }
                                        </RowCell>
                                    );
                                })}
                            </Block>
                        </Row>
                    );
                })}
                {hasMore && <Block center mt={24}>
                    <Button color={colors.grey01} label={"Load more"} onClick={next} />
                </Block>}
            </Rows>
        </Wrapper>
    );
}

export default AdvancedTable;

const Wrapper = styled.div`
    
`;

const Columns = styled(Block)`
    position: sticky;
    top: 0;
    background-color: ${colors.grey05};
`;

const Column = styled(Block)`
    height: 100%;
    min-width: 131px;
    background-color: ${colors.grey10};

    :first-of-type {
        padding-left: 24px;
    }
`;

const Rows = styled.div`
    /* overflow-y: scroll;
    height: calc(100% - 40px); */
`;

const RowCell = styled(Block)`
    height: 52px;
    min-width: 131px;
    padding: 0 16px;

    justify-content: center;
    
    :first-of-type {
        padding-left: 24px;
    }

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Row = styled(Block)`
    width: 100%;
    height: 52px;
    min-height: 52px;
    flex-flow: row !important;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 8px;

    ${RowCell} {
        background-color: ${colors.white01};
    }
`;