import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import { Block, Button, Form, Icon, Input, Text, TextInput } from '../../components';
import { SvgEye } from '../../svgs';
import { getClientToken } from '../../utils/client';
import colors from '../../utils/colors';

function PasswordRecoveryConfirmation() {
    const [cookies, setCookie] = useCookies(["evidence_match_session"]);
    const history = useHistory();
    const { token } = useParams();
    const [sent, setSent] = useState(false);
    const [viewPassword, setViewPassword] = useState(false);

    const [mutateUserPasswordRecoveryConfirmation, { loading }] = useMutation(
        gql`
            mutation userPasswordRecoveryConfirmation($token: String!, $password: String!) {
                userPasswordRecoveryConfirmation(token: $token, password: $password)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userPasswordRecoveryConfirmation) {
                    setSent(true);
                }
            }
        }
    );

    if (getClientToken() || cookies.evidence_match_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Container center middle>
            <ButtonBack center middle pointer onClick={() => history.goBack()}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>
            {sent ?
                <Block width={353} center>
                    <Text size={56} height={64} bold center>Perfect!</Text>
                    <Text size={23} height={32} bold center mt={8} mb={56}>Your password has been updated.</Text>
                    {/* <Text size={16} height={24} center mt={24} mb={56} color={colors.grey03}>Nam nec leo pulvinar, scelerisque neque vel, iaculis purus. Interdum et malesuada fames ac ante ipsum primis in faucibus.</Text> */}
                    <Button label="Go to my login" color={colors.purple04} onClick={() => history.push("/login")} />
                </Block>
                :
                <Center br={16} width={452} height={332} pt={24} pr={24} pb={24} pl={24}>
                    <Text size={24} height={32} bold>Create new password</Text>
                    <Form
                        schema={yup.object().shape({
                            password: yup.string().required()
                        })}
                        onSubmit={(values) => {
                            mutateUserPasswordRecoveryConfirmation({ variables: { token, ...values } });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block mt={36}>
                                        <Text size={12} height={16} bold mb={12}>NEW PASSWORD</Text>
                                        <div style={{ position: "relative" }}>
                                            <Input Component={TextInput} type={viewPassword ? "text" : "password"} name="password" error={errors.password} placeholder="New password" />
                                            <div style={{ cursor: "pointer", position: "absolute", right: 16, top: 18 }} onClick={() => setViewPassword(!viewPassword)}>
                                                <SvgEye />
                                            </div>
                                        </div>
                                    </Block>
                                    <Block mt={84}>
                                        <Button type="submit" label="Send" loading={loading} color={colors.purple04} width="137px" />
                                    </Block>
                                </form>
                            )
                        }}
                    </Form>
                </Center>}
        </Container >
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default PasswordRecoveryConfirmation;
