import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from 'graphql-tag';
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Block, Button, CheckInput, Form, FormGroup, Icon, Input, Loading, ModalThatSlides, Portlet, Text, TextInput } from '../../../../components';
import { getClientToken } from "../../../../utils/client";
import colors from "../../../../utils/colors";

export default function ModalEM({ candidatureId, isOpen, onRequestClose }) {
    const totalChecks = 10;
    const formRef = useRef(null);
    const [fetching, setFetching] = useState(false);
    const [candidature, setCandidature] = useState(null);
    const [score, setScore] = useState(0);
    const [valuesAux, setValuesAux] = useState(null);

    const [queryCandidature, { loading: loadingCandidature }] = useLazyQuery(
        gql`
            query candidature($id: ID!) {
                candidature(id: $id) {
                    id
                    evidenceMatchScore
                    evidenceMatchScoreItems
                    video
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidature) {
                    setCandidature(data.candidature);
                    setScore(data.candidature.evidenceMatchScore);
                }
            }
        }
    );

    const [mutateCandidatureUpdate, { loading: loadingCandidatureUpdate }] = useMutation(
        gql`
            mutation candidatureUpdate(
                $id: ID!,
                $evidenceMatchScore: String,
                $evidenceMatchScoreItems: Json
            ) {
                candidatureUpdate(
                    id: $id
                    evidenceMatchScore: $evidenceMatchScore
                    evidenceMatchScoreItems: $evidenceMatchScoreItems
                ) {
                    id
                    evidenceMatchScore
                    evidenceMatchScoreItems
                    video
                }
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.candidatureUpdate) {
                    !!onRequestClose && onRequestClose();
                }
            }
        }
    );

    useEffect(() => {
        if (!isOpen) {
            setCandidature(null);
            setScore(0);
            setValuesAux(null);
        }
    }, [isOpen]);

    useEffect(() => {
        if (!!candidatureId) {
            queryCandidature({ variables: { id: candidatureId } });
        }
    }, [candidatureId]);

    const exportRequest = () => {
        if (!fetching) {
            setFetching(true);

            fetch(`${process.env.REACT_APP_BACKEND_HOST}/pdfs/generate/em/${candidatureId}`, {
                method: "GET",
                headers: { "Authorization": getClientToken() }
            })
                .then(res => res.blob())
                .then(blob => {
                    var file = window.URL.createObjectURL(blob);
                    window.open(file, "_blank");
                    setFetching(false);
                })
                .catch(() => {
                    setFetching(false);
                });
        }
    }

    useEffect(() => {
        if (!!valuesAux && !!candidature && JSON.stringify(valuesAux) !== JSON.stringify(candidature.evidenceMatchScoreItems)) {
            setScore((Object.keys(valuesAux).filter((key) => valuesAux[key]).length * 100 / totalChecks).toFixed(2));
        }
    }, [JSON.stringify(valuesAux)]);

    return (
        <ModalThatSlides
            id="candidate-em"
            title="Evidence match"
            width="820px"
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            actions={
                <Block row>
                    <Block row center pl={16} width={176} height={52} color={colors.yellow01} pointer onClick={exportRequest}>
                        {fetching ?
                            <Loading />
                            :
                            <>
                                <Icon name="upload_20px" color={colors.white01} />
                                <Text size={14} height={28} color={colors.white01} ml={8}>Export EM</Text>
                            </>}
                    </Block>
                    <Block
                        row
                        center
                        pl={16}
                        width={176}
                        height={52}
                        color={colors.grey02}
                        pointer
                        onClick={() => {
                            (!loadingCandidatureUpdate && !!formRef.current) && formRef.current.handleSubmit();
                        }}
                    >
                        <Text size={14} height={28} color={colors.white01}>{!!loadingCandidatureUpdate ? "Loading..." : "Save"}</Text>
                    </Block>
                    <Block row center middle width={52} height={52} color={colors.grey01} pointer onClick={onRequestClose}>
                        <Icon name="close_16px" color={colors.white01} />
                    </Block>
                </Block>
            }
        >
            {(loadingCandidature) && <Block width="100%" height="100%" center middle><Loading color={colors.grey01} /></Block>}
            {!!candidature && <Form
                innerRef={formRef}
                initialValues={candidature.evidenceMatchScoreItems || {}}
                onSubmit={(values) => {
                    if (!loadingCandidatureUpdate) {
                        mutateCandidatureUpdate({ variables: { id: candidatureId, evidenceMatchScore: score, evidenceMatchScoreItems: values } });
                    }
                }}
            >
                {({
                    values,
                    handleSubmit,
                }) => {
                    setValuesAux(values);
                    return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Portlet width="100%">
                                <FormGroup title="Success Score">
                                    <Block row>
                                        <Block width={256} row center>
                                            <TextInput placeholder="Score" value={score} onChangeValue={(value) => setScore(value)} />&nbsp;%
                                        </Block>
                                        {!!candidature.video && <Block ml={12}>
                                            <Button type="button" icon="upload_20px" label="Export candidate video" color={colors.grey01} onClick={() => { window.open(candidature.video.url, "_blank") }} />
                                        </Block>}
                                    </Block>
                                </FormGroup>
                                <Block>
                                    <Text size={14} height={17} color={colors.grey01} mt={32}>Fill out the questionnaire below to add a success score to the candidate evidence match final report</Text>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="GEOGRAPHY" label="01 . Geography" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Localization</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Availability for mobility</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Remote work</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Availability to travel</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="ACADEMIC_RECORD" label="02 . Academic Record" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Education/training institutions</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Academic background - reasons</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Continuity of Studies</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="BUSSINESS_HISTORY" label="03 . Business History" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Company, Dimension and Sector</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Cultural Fit</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Reasons for Leaving</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="HISTORY_OF_DEVELOPED_PROJECTS" label="04 . History of Developed Projects" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Projects in which he was involved</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Size of projects – how many people; to whom he reported and who reported to him</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Challenges, opportunities that arose throughout projects</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Dealing with unforeseen issues and delivery times vs. delivery quality of the project to the client</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Good practices you use</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="LANGUAGES" label="05 . Languages" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Fluency level in other languages</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Technical and specialized communication in other languages</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="CURRICULUM" label="06 . Curriculum / Personal presentation" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Presentation and robustness of the curriculum</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Candidate's physical, intellectual and commitment presentation</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Feedback to the candidate on what can be improved on their CV and personal presentation</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="MOTIVATIONAL_DRIVE" label="07 . Motivational drive" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Why do you want to change?</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Why pay attention to the market?</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>What would make you get out of the current situation?</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Why are you the right fit for this job and company?</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="AVAILABILITY" label="08 . Availability" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Availability (date) to embrace the new project</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Family availability when it comes to national or international mobility projects</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="SALARY" label="09 . Salary and additional conditions" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Salary expectation framework</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Framework and expectation of benefits</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Contractual framework and expectations</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                    <Block mt={24}>
                                        <Input Component={CheckInput} name="RECRUITER_INTUITION" label="10 . Recruiter's intuition" />
                                        <Block mt={10}>
                                            <UL>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Recruiter's sensitivity and "feeling" to send the candidate</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Knowledge of the job vacancy and candidate matching.</Text>
                                                </li>
                                                <li>
                                                    <Text size={14} height={24} color={colors.grey03}>Is this candidate a GO or a NO GO?</Text>
                                                </li>
                                            </UL>
                                        </Block>
                                    </Block>
                                </Block>
                            </Portlet>
                        </form>
                    )
                }}
            </Form>}
        </ModalThatSlides>
    );
}

const UL = styled.ul`
    margin-left: 16px;

    li {
        margin-top: 8px;
        color: ${colors.grey03};

        ::before {
            color: ${colors.grey03};
        }

        :first-of-type {
            margin-top: 0px;
        }
    }
`;