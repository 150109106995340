import React from "react";
import { Block, Button, FieldArray, FormGroup, Input, PortletAccordionItem, TextAreaInput, TextInput } from "../../../../components";
import colors from "../../../../utils/colors";

export default function ListTechnicalSkillsHighlights({ value = [] }) {
    return (
        <FieldArray
            name={"technicalSkills"}
            render={(arrayHelpers) => {
                return (
                    <>
                        {value.map((_item, index) => {
                            return (
                                <PortletAccordionItem
                                    key={index}
                                    title={"Skill" + " #" + (index + 1)}
                                    onClickDelete={() => arrayHelpers.remove(index)}
                                    onClickMoveDown={() => arrayHelpers.move(index, index + 1)}
                                    onClickMoveUp={() => arrayHelpers.move(index, index - 1)}
                                >
                                    <FormGroup>
                                        <Block width={456}>
                                            <Input Component={TextInput} name={`technicalSkills[${index}].skill`} placeholder="Skill" />
                                        </Block>
                                    </FormGroup>
                                    <FormGroup>
                                        <Block width={456}>
                                            <Input Component={TextAreaInput} name={`technicalSkills[${index}].description`} placeholder="Description of the skill" />
                                        </Block>
                                    </FormGroup>
                                </PortletAccordionItem>
                            );
                        })}
                        <Block>
                            <Button width="100%" icon="plus_16px" color={colors.grey01} onClick={() => arrayHelpers.insert(value.length, {})} />
                        </Block>
                    </>
                );
            }}
        />
    );
}