import { useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import * as yup from 'yup';
import { Block, Button, Form, Icon, Input, Text, TextInput } from '../../components';
import { getClientToken } from '../../utils/client';
import colors from '../../utils/colors';

function PasswordRecovery() {
    const [cookies, setCookie] = useCookies(["evidence_match_session"]);
    const history = useHistory();
    const [sent, setSent] = useState(false);

    const [mutateUserPasswordRecovery, { loading }] = useMutation(
        gql`
            mutation userPasswordRecovery($email: String!) {
                userPasswordRecovery(email: $email)
            }
        `,
        {
            onError: () => { },
            onCompleted: (data) => {
                if (data && data.userPasswordRecovery) {
                    setSent(true);
                }
            }
        }
    );

    if (getClientToken() || cookies.evidence_match_session) {
        history.push("/dashboard");
        return null;
    }

    return (
        <Container center middle>
            <ButtonBack center middle pointer onClick={() => history.goBack()}>
                <Icon name="arrow_left_20px" />
            </ButtonBack>
            {sent ?
                <Block width={353}>
                    <Text size={56} height={64} bold center>Done!</Text>
                    <Text size={23} height={32} bold center mt={8}>We've sent an email to you with password reset instructions.</Text>
                    <Text size={16} height={24} center mt={24} color={colors.grey03}>If the email doesn't show up soon, check your spam folder. We sent it from talent@findhu.pt</Text>
                </Block>
                :
                <Center br={16} width={452} height={432} pt={24} pr={24} pb={24} pl={24}>
                    <Text size={24} height={32} bold center>Recover your password</Text>
                    <Form
                        schema={yup.object().shape({
                            email: yup.string().email().required()
                        })}
                        onSubmit={(values) => {
                            mutateUserPasswordRecovery({ variables: values });
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Block mt={20}>
                                        <Text size={16} height={24} mb={20} center color={colors.grey03}>Enter an email address and we will send you a link to recover your password</Text>
                                        <Input Component={TextInput} name="email" error={errors.email} placeholder="Email of the account" />
                                    </Block>
                                    <Block mt={104} center>
                                        <Button type="submit" label="Send recovery link" loading={loading} color={colors.purple04} />
                                    </Block>
                                </form>
                            )
                        }}
                    </Form>
                </Center>}
        </Container >
    );
}

const Container = styled(Block)`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Center = styled(Block)`
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
`;

const ButtonBack = styled(Block)`
    position: absolute;
    top: 24px;
    left: 22px;
    width: 52px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(102, 102, 102, 0.16);
    border-radius: 26px;
    z-index: 99;
`;

export default PasswordRecovery;
